import impressumHero from '../../assets/imgs/home/gallery/1.jpg';

const Impressum = () => { 
  return (
    <div id='impressum' className='impressum'>
      <div className='impressum_hero'>
        <img src={impressumHero} alt='impressum hero' />
      </div>
      <div className='impressum_container container'>
        <h1>Impressum</h1>
        <h3>Property name</h3>
        <p>KORTA Apartments</p>
        <h3>Property address</h3>
        <p>
          Plinarska ulica 31
          <br />
          HR-21000 SPLIT
          <br />
          Croatia
        </p>
        <h3>Company name</h3>
        <p>MANAS d.o.o. (LLC)</p>
        <h3>Company address</h3>
        <p>
          Barakovićeva 18
          <br />
          HR-21000 SPLIT
          <br />
          Croatia
        </p>
        <h3>VAT</h3>
        <p>HR77290534017</p>
        <h3>Commercial court</h3>
        <p>
          The company is signed into a court register at Commercial court in
          Split under the number 060086339.
        </p>
        <h3>Phone</h3>
        <p>
          <a href='tel:+385 21 571 226' className='opacity_transition_low'>
            +385 21 571 226
          </a>
          <br />
          <a href='tel:+385 98 571 226' className='opacity_transition_low'>
            +385 98 571 226
          </a>
        </p>
        <h3>Email</h3>
        <p>
          <a href='mailto:reservations@kortasplit.com' className='opacity_transition_low'>
            reservations@kortasplit.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Impressum;
