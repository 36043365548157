import Slideshow from '../../../components/slideshow/Slideshow';
import Constants from '../../../utils/Constants';
import separator from '../../../assets/imgs/separator.svg';
import img1 from '../../../assets/imgs/accommodations/room/1.jpg';
import img2 from '../../../assets/imgs/accommodations/room/2.jpg';
import img3 from '../../../assets/imgs/accommodations/room/3.jpg';
import img4 from '../../../assets/imgs/accommodations/room/4.jpg';
import img5 from '../../../assets/imgs/accommodations/room/5.jpg';
import FadeInSection from '../../../utils/FadeInSection';

const roomImages: Array<string> = [img1, img2, img3, img4, img5];

const Room = () => {
  FadeInSection();

  return (
    <div id='room' className='accommodations_wrapper'>
      <div className='accommodations'>
        <Slideshow name='Room' slideshowImages={roomImages} />
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <h1 hidden>Room</h1>
        <div className='accommodations_container_info'>
          <div className='accommodations_container_info_text fade_in'>
            Located on the ground floor, Korta room provides pleasant
            accommodation for one person. This room features modern furniture,
            comfortable bed, LCD TV, writing desk and bathroom with walk-in
            shower.
            <div className='accommodations_container_info_text_button'>
              <a
                href={Constants.bookNow}
                target='_blank'
                rel='noreferrer'
                className='opacity_transition_low'
              >
                <div>BOOK NOW</div>
              </a>
            </div>
          </div>
          <div className='accommodations_container_info_details'>
            <div className='fade_in'>
              <p>ENTERTAINMENT</p>
              <ul>
                <li>LCD TV</li>
              </ul>
              <p>ROOM FEATURES</p>
              <ul>
                <li>Wi-Fi</li>
                <li>Air conditioning</li>
                <li>Writing desk</li>
                <li>Safe deposit box</li>
                <li>Wardrobe</li>
                <li>Reading light</li>
                <li>Electronic door lock</li>
                <li>Non-smoking</li>
                <li>Pets allowed</li>
              </ul>
            </div>
            <div className='fade_in'>
              <p>BED FEATURES</p>
              <ul>
                <li>Fine beddings</li>
                <li>Hypoallergenic pillows</li>
              </ul>
              <p>BATHROOM</p>
              <ul>
                <li>Walk-in shower</li>
                <li>Toiletries</li>
                <li>Towels</li>
                <li>Hairdryer</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Room;
