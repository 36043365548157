import FadeInSection from '../../../utils/FadeInSection';
import Slideshow from '../../../components/slideshow/Slideshow';
import Constants from '../../../utils/Constants';
import separator from '../../../assets/imgs/separator.svg';
import img1 from '../../../assets/imgs/accommodations/duplex/1.jpg';
import img2 from '../../../assets/imgs/accommodations/duplex/2.jpg';
import img3 from '../../../assets/imgs/accommodations/duplex/3.jpg';
import img4 from '../../../assets/imgs/accommodations/duplex/4.jpg';
import img5 from '../../../assets/imgs/accommodations/duplex/5.jpg';
import img6 from '../../../assets/imgs/accommodations/duplex/6.jpg';
import img7 from '../../../assets/imgs/accommodations/duplex/7.jpg';
import img8 from '../../../assets/imgs/accommodations/duplex/8.jpg';
import img9 from '../../../assets/imgs/accommodations/duplex/9.jpg';
import img10 from '../../../assets/imgs/accommodations/duplex/10.jpg';
import img11 from '../../../assets/imgs/accommodations/duplex/11.jpg';

const duplexImages: Array<string> = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11
];

const Duplex = () => {
  FadeInSection();

  return (
    <div id='duplex' className='accommodations_wrapper'>
      <div className='accommodations'>
        <Slideshow name='Duplex' slideshowImages={duplexImages} />
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <h1 hidden>Duplex</h1>
        <div className='accommodations_container_info'>
          <div className='accommodations_container_info_text fade_in'>
            Modern in design, Korta apartment is south oriented and situated on
            the first floor, offering high comfort for up to three persons.
            Within this apartment, guests have numerous amenities at their
            disposal, such as elegant furnishings, separate bedroom, modern
            decorated bathroom and fully equipped kitchen.
            <div className='accommodations_container_info_text_button'>
              <a
                href={Constants.bookNow}
                target='_blank'
                rel='noreferrer'
                className='opacity_transition_low'
              >
                <div>BOOK NOW</div>
              </a>
            </div>
          </div>
          <div className='accommodations_container_info_details'>
            <div className='fade_in'>
              <p>ENTERTAINMENT</p>
              <ul>
                <li>LCD TV</li>
              </ul>
              <p>ROOM FEATURES</p>
              <ul>
                <li>Wi-Fi</li>
                <li>Air conditioning</li>
                <li>Safe deposit box</li>
                <li>Wardrobe</li>
                <li>Reading light</li>
                <li>Electronic door lock</li>
                <li>Non-smoking</li>
                <li>Pets allowed</li>
              </ul>
            </div>
            <div className='fade_in'>
              <p>BED FEATURES</p>
              <ul>
                <li>Fine beddings</li>
                <li>Hypoallergenic pillows</li>
              </ul>
              <p>BATHROOM</p>
              <ul>
                <li>Walk-in shower</li>
                <li>Toiletries</li>
                <li>Towels</li>
                <li>Hairdryer</li>
              </ul>
              <p>DINING</p>
              <ul>
                <li>Kitchen</li>
                <li>Kitchen table and chairs</li>
                <li>Cutlery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Duplex;
