import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Constants from '../../utils/Constants';
import kortaLogo from '../../assets/imgs/korta_logo_white.svg';
import kortaMenu from '../../assets/imgs/korta_menu.jpg';
import scrollToPage from '../../utils/ScrollToLink';
import scrollDirection from '../../utils/ScrollDirection';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const direction = scrollDirection();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  let header: HTMLElement | null = document.getElementById('nav_container');
  let headerBackground: HTMLElement | null =
    document.getElementById('nav_background');
  let logo: HTMLElement | null = document.getElementById('nav_logo');

  const handleLink = (target: string) => {
    setOpenMenu(!openMenu);
    scrollToPage(target, location, navigate);

    setTimeout(() => {
      if (
        header !== null &&
        headerBackground !== null &&
        window.scrollY > 96
      ) {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';
      }
    }, 600);
  };

  const handelHeaderAppearance = () => {
    if (header !== null && headerBackground !== null) {
      if (direction === 'up') {
        headerBackground.style.top = '0';
        header.style.top = '0';

        if (window.scrollY < 96) {
          headerBackground.style.background = 'transparent';
        }
      } else if (direction === 'down') {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';

        if (window.scrollY >= 96) {
          headerBackground.style.background = Constants.complementaryColorLight;
        }
      }
    }
  };

  window.onscroll = () => {
    handelHeaderAppearance();
  };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('lock_scroll');
      document.getElementById('nav_menu')?.classList.add('open_sidemenu');
      
      if (logo !== null && document.body.clientWidth <= 650) {
        logo.style.filter = 'invert(1)';
      }
    } else {
      document.body.classList.remove('lock_scroll');
      document.getElementById('nav_menu')?.classList.remove('open_sidemenu');

      if (logo !== null) {
        logo.style.filter = 'invert(0)';
      }
    }
  }, [openMenu]);

  return (
    <div className='nav_wrapper'>
      <div id='nav_container'>
        <div className='nav_menu'>
          <div className='nav_menu_burger'>
            <input
              className='nav_menu_burger_cheeckbox'
              type='checkbox'
              onChange={() => setOpenMenu(!openMenu)}
              checked={openMenu}
            />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className='nav_logo' onClick={() => scrollToPage('home', location, navigate)}>
          <img src={kortaLogo} alt='korta logo' id='nav_logo' />
        </div>
        <div className='nav_book'>
          <a
            href={Constants.bookNow}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>BOOK NOW</div>
          </a>
        </div>
      </div>
      <div id='nav_background'></div>
      <div id='nav_menu'>
        <div className='nav_menu_list'>
          <div className='nav_menu_list_links'>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('home')}
            >
              home
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('about')}
            >
              about us
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('accommodations')}
            >
              room & apartments
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('discover')}
            >
              discover split
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('gallery')}
            >
              gallery
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('footer')}
            >
              contact us
            </div>
          </div>
          <div className='nav_menu_list_image'>
            <img src={kortaMenu} alt='korta menu' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
