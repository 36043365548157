import React from 'react';
import { useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../utils/Constants';

interface SlideshowProps {
  name?: string;
  slideshowImages: Array<string>;
}

const Slideshow = (props: SlideshowProps) => {
  const slideshow = React.useRef<Slider | null>(null);
  const [count, setCount] = useState<number>(1);
  const [preventDoubleClick, setPreventDoubleClick] = useState<boolean>(false);
  const transitionSpeed: number = 500;

  const nextSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      slideshow.current?.slickNext();

      if (count === props.slideshowImages.length) {
        setCount(1);
      } else {
        setCount(count + 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed + 100);
    }
  };

  const prevSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      slideshow.current?.slickPrev();

      if (count === 1) {
        setCount(props.slideshowImages.length);
      } else {
        setCount(count - 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed);
    }
  };

  const slickSettings = {
    draggable: false,
    swipe: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    speed: transitionSpeed,
  };

  return (
    <div className={`slideshow ${!props.name ? 'fade_in' : undefined}`}>
      <Slider ref={slideshow} {...slickSettings}>
        {props.slideshowImages.map((x: string) => (
          <div className='slideshow_img'>
            <img src={x} alt={`slideshow${props.slideshowImages.indexOf(x)}`} />
          </div>
        ))}
      </Slider>
      <div
        className={`slideshow_info ${props.name ? 'container' : undefined} ${
          props.name ? 'with_name' : undefined
        }`}
      >
        <div
          className='slideshow_info_default'
          style={!props.name ? { marginTop: '1rem' } : undefined}
        >
          <div className='slideshow_info_default_text'>
            {count} / {props.slideshowImages.length}
          </div>
          <div className='slideshow_info_default_buttons'>
            <div className='opacity_transition_low' onClick={() => prevSlide()}>
              {Constants.arrowLeft(Constants.secondaryColor)}
            </div>
            <div className='opacity_transition_low' onClick={() => nextSlide()}>
              {Constants.arrowRight(Constants.secondaryColor)}
            </div>
          </div>
        </div>
        {props.name && (
          <div className='slideshow_info_name'>
            <h2>{props.name}</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slideshow;
