import kortaHero1 from '../../assets/videos/korta_video_1.mp4';
import kortaHero2 from '../../assets/videos/korta_video_2.mp4';
import About from './components/about/About';
import Services from './components/services/Services';
import RoomAndApartments from './components/roomAndApartments/RoomAndApartments';
import Discover from './components/discover/Discover';
import Gallery from './components/gallery/Gallery';
import FadeInSection from '../../utils/FadeInSection';
import Constants from '../../utils/Constants';
import firstFrame from '../../assets/imgs/home/video_first_frame.jpg';

const Home = () => {
  FadeInSection();

  return (
    <div id='home'>
      <div className='home_container_hero'>
        <video
          poster={firstFrame}
          preload='auto'
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
        >
          <source src={kortaHero1} type='video/mp4' />
          {/* iOS  */}
          <source src={kortaHero2} type='video/mp4' />
        </video>
        <div className='home_container_hero_overlay'></div>
        <div className='home_container_hero_button'>
          <a
            href={Constants.bookNow}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>BOOK NOW</div>
          </a>
        </div>
      </div>
      <h1 hidden>Korta Split</h1>
      <About />
      <Services />
      <RoomAndApartments />
      <Discover />
      <Gallery />
    </div>
  );
};

export default Home;
