import Slideshow from '../../../components/slideshow/Slideshow';
import Constants from '../../../utils/Constants';
import separator from '../../../assets/imgs/separator.svg';
import img1 from '../../../assets/imgs/accommodations/apartments/1.jpg';
import img2 from '../../../assets/imgs/accommodations/apartments/2.jpg';
import img3 from '../../../assets/imgs/accommodations/apartments/3.jpg';
import img4 from '../../../assets/imgs/accommodations/apartments/4.jpg';
import img5 from '../../../assets/imgs/accommodations/apartments/5.jpg';
import img6 from '../../../assets/imgs/accommodations/apartments/6.jpg';
import img7 from '../../../assets/imgs/accommodations/apartments/7.jpg';
import img8 from '../../../assets/imgs/accommodations/apartments/8.jpg';
import img9 from '../../../assets/imgs/accommodations/apartments/9.jpg';
import img10 from '../../../assets/imgs/accommodations/apartments/10.jpg';
import img11 from '../../../assets/imgs/accommodations/apartments/11.jpg';
import img12 from '../../../assets/imgs/accommodations/apartments/12.jpg';
import img13 from '../../../assets/imgs/accommodations/apartments/13.jpg';
import img14 from '../../../assets/imgs/accommodations/apartments/14.jpg';
import img15 from '../../../assets/imgs/accommodations/apartments/15.jpg';
import img16 from '../../../assets/imgs/accommodations/apartments/16.jpg';
import img17 from '../../../assets/imgs/accommodations/apartments/17.jpg';
import img18 from '../../../assets/imgs/accommodations/apartments/18.jpg';
import FadeInSection from '../../../utils/FadeInSection';

const apartmentsImages: Array<string> = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
];

const Apartments = () => {
  FadeInSection();

  return (
    <div id='apartments' className='accommodations_wrapper'>
      <div className='accommodations'>
        <Slideshow name='Apartments' slideshowImages={apartmentsImages} />
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <h1 hidden>Apartments</h1>
        <div className='accommodations_container_info'>
          <div className='accommodations_container_info_text fade_in'>
            Tastefully decorated, Korta studio apartments offer high-quality
            comfortable accommodation for two. These apartments consist of
            comfortable bed, kitchen area and bathroom with walk-in shower.
            <div className='accommodations_container_info_text_button'>
              <a
                href={Constants.bookNow}
                target='_blank'
                rel='noreferrer'
                className='opacity_transition_low'
              >
                <div>BOOK NOW</div>
              </a>
            </div>
          </div>
          <div className='accommodations_container_info_details'>
            <div className='fade_in'>
              <p>ENTERTAINMENT</p>
              <ul>
                <li>LCD TV</li>
              </ul>
              <p>ROOM FEATURES</p>
              <ul>
                <li>Wi-Fi</li>
                <li>Air conditioning</li>
                <li>Safe deposit box</li>
                <li>Wardrobe</li>
                <li>Reading light</li>
                <li>Electronic door lock</li>
                <li>Non-smoking</li>
                <li>Pets allowed</li>
              </ul>
            </div>
            <div className='fade_in'>
              <p>BED FEATURES</p>
              <ul>
                <li>Fine beddings</li>
                <li>Hypoallergenic pillows</li>
              </ul>
              <p>BATHROOM</p>
              <ul>
                <li>Walk-in shower</li>
                <li>Toiletries</li>
                <li>Towels</li>
                <li>Hairdryer</li>
              </ul>
              <p>DINING</p>
              <ul>
                <li>Kitchen</li>
                <li>Kitchen table and chairs</li>
                <li>Cutlery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apartments;
