import Slideshow from '../../../../components/slideshow/Slideshow';
import img1 from '../../../../assets/imgs/home/gallery/1.jpg';
import img2 from '../../../../assets/imgs/home/gallery/2.jpg';
import img3 from '../../../../assets/imgs/home/gallery/3.jpg';
import img4 from '../../../../assets/imgs/home/gallery/4.jpg';
import img5 from '../../../../assets/imgs/home/gallery/5.jpg';
import img6 from '../../../../assets/imgs/home/gallery/6.jpg';
import img7 from '../../../../assets/imgs/home/gallery/7.jpg';
import img8 from '../../../../assets/imgs/home/gallery/8.jpg';
import img9 from '../../../../assets/imgs/home/gallery/9.jpg';
import img10 from '../../../../assets/imgs/home/gallery/10.jpg';
import img11 from '../../../../assets/imgs/home/gallery/11.jpg';
import img12 from '../../../../assets/imgs/home/gallery/12.jpg';
import img13 from '../../../../assets/imgs/home/gallery/13.jpg';
import img14 from '../../../../assets/imgs/home/gallery/14.jpg';

const Gallery = () => {
  const galleryImages: string[] = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
  ];

  return (
    <div id='gallery'>
      <div className='gallery container'>
        <h2>Gallery</h2>
        <div className='gallery_container'>
          <Slideshow slideshowImages={galleryImages} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
